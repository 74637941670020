/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"

import { Link } from "gatsby"

import { useForm } from 'react-hook-form';

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import { graphql } from 'gatsby'

const useStyles = makeStyles(contactUsStyle);

export const query = graphql`
  fragment Address on Cockpit_aboutSingleton {
        address
        telephone
        email
  }
`

const ContactDetails = ({address, telephone, email}) => {
  const { register, handleSubmit, errors } = useForm();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();



  const onSubmit = (data) => {
    const cmsURL = "https://cloud.completeconstruction.gr/"
    const token = 'token=account-c81a35fa38d530d274b2b27cf1527b'
    const url = cmsURL+'api/forms/submit/contact?'+token
    console.log(data)
    const form_data = JSON.stringify({
      form: data
    })
    console.log(form_data)
    fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: form_data,
  })
  .then(
    data => {
      console.log(data)
      if (data.status == 200) {
        alert("Επιτυχής αποστολή μηνύματος")
    }
  }
  );
  }


  return (
    <div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
        
          <div className={classes.container}>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "2rem" }}>
            <Link color="inherit" to="/">
              Αρχική
            </Link>
            <Typography color="textPrimary">Επικοινωνία</Typography>
          </Breadcrumbs>
            <h2 className={classes.title}>Επικοινωνία</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  Στείλτε μας μήνυμα
                  <br />
                  <br />
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CustomInput
                    labelText="Όνομα"
                    id="float"
                    type="text"
                    name="name"
                    inputRef={register({required: true, maxLength: 100})}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Διεύθυνση email"
                    id="float"
                    type="email"
                    name="sender"
                    inputRef={register({required: true, pattern: /^\S+@\S+$/i})}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Τηλέφωνο"
                    id="float"
                    type="tel"
                    name="telephone"
                    inputRef={register({required: true, minLength: 6, maxLength: 12})}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Το μήνυμά σας"
                    id="float"
                    type="text"
                    name="message"
                    inputRef={register({required: true, maxLength: 500})}
                    // inputProps = {register({required: true, maxLength: 500})}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6
                    }}
                  />
                  <div className={classes.textCenter}>
                    {/* <Button round onClick={handleSubmit(onSubmit)}>
                      Αποστολη
                    </Button> */}
                    <input type="submit" value="Αποστολή" />
                  </div>
                </form>
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Βρείτε μας στο γραφείο"
                  description={
                    <p>
                      {address}
                      <br /> Ελλάδα
                      <br /> 
                      <a href="https://www.google.com/maps/dir//Complete+Construction+%CE%A3%CE%BA%CE%BF%CF%85%CE%BB%CE%AC%CF%82-%CE%9C%CF%80%CE%AD%CF%84%CF%83%CE%B9%CE%BF%CF%82,+%CE%9A%CE%BF%CF%85%CF%84%CE%B1%CF%81%CE%AD%CE%BB%CE%B9%CE%B1,+%CE%92%CF%8C%CE%BB%CE%BF%CF%82/@39.3623104,22.9394031,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14a76c65a3c1aaa7:0x17faeadaa65453f9!2m2!1d22.9439763!2d39.3606513?rapsrc=apiv3" target="_blank">Οδηγίες μετάβασης</a>
                    </p>
                  }
                  icon={PinDrop}
                  // iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Επικοινωνία"
                  description={
                    <p>
                      <a href={"mailto:"+email} alt="email">{email}</a><br />
                      {telephone}<br /> Δευτέρα - Παρασκευή,
                      9:00-17:00
                    </p>
                  }
                  icon={Phone}
                  // iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}


export default ContactDetails