/*eslint-disable*/
import React from "react"
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js"

import { graphql } from 'gatsby'

export const query = graphql`
  fragment Location on Cockpit_aboutSingleton {
    location{
        lat,
        lng
    }
  }
`

const CustomSkinMap = withScriptjs(
  withGoogleMap(({ coordinates }) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={coordinates}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }],
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }],
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }],
          },
        ],
      }}
    >
      <Marker position={coordinates} />
    </GoogleMap>
  ))
)

const useStyles = makeStyles(contactUsStyle)

// const coordinates = { lat: 44.43353, lng: 26.093928 }

const GoogleCustomMap = ({ coordinates, api_key }) => {
  const classes = useStyles()

  return (
    <div className={classes.bigMap}>
      <CustomSkinMap
        coordinates={coordinates}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${api_key}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div
            style={{
              height: `100%`,
              borderRadius: "6px",
              overflow: "hidden",
            }}
          />
        }
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  )
}

export default GoogleCustomMap
