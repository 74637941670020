import React from "react"
import PageLayout from "components/PageLayout"
import GoogleCustomMap from "components/GoogleCustomMap"
import ContactDetails from "components/ContactDetails"

import SEO from "../components/Seo"
import useSiteMetadata from "../hooks/use-site-metadata"

import { graphql } from "gatsby"

export const query = graphql`
  {
    cmsAPI {
      aboutSingleton {
        ...Location
        ...Address
      }
    }
  }
`

export default ({ data, location }) => {
  const { logo_black } = useSiteMetadata()

  return (
    <PageLayout>
      <SEO
        title="Επικοινωνία"
        description={"Επικοινωνήστε μαζί μας"}
        image={"https://cloud.completeconstruction.gr/api/imagestyles/style/large?token=account-f78d7e702ad39285beb47738e16c2d&output=1&src=/storage/uploads/2020/10/01/5f75fe41ccd32Lab4_COMPLETE_1.jpg"}
        pathname={location.pathname}
      />
      <GoogleCustomMap
        coordinates={data.cmsAPI.aboutSingleton.location}
        api_key="AIzaSyBfVlBxqs3TZh3d0YNLkZPPblzLIZDxKN8"
      />
      <ContactDetails
        address={data.cmsAPI.aboutSingleton.address}
        telephone={data.cmsAPI.aboutSingleton.telephone}
        email={data.cmsAPI.aboutSingleton.email}
      />
    </PageLayout>
  )
}
